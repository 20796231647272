<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row class="mx-auto my-2">
              <v-col cols="12" sm="6" align-self="center">
                <h3 :class="this.$vuetify.breakpoint.xs ? 'text-center': '' + ' ma-0'">Overall Order and Delivery Rating</h3>
              </v-col>
              <v-col cols="12" sm="6" align-self="center">
                <div :class="this.$vuetify.breakpoint.xs ? 'text-center': ''">
                  <v-rating
                    v-model="ratings['overall']"
                    :rating="rating"
                    background-color="orange lighten-3"
                    color="orange"
                    large
                  >
                  </v-rating>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h4 class="mb-0">More rating options</h4>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" sm="6" align-self="center">
                          <h4>Was your order what you expected?</h4>
                        </v-col>
                        <v-col cols="12" sm="6" align-self="center">
                          <div :class="this.$vuetify.breakpoint.xs ? 'text-center': ''">
                            <v-rating
                              v-model="ratings['expected']"
                              :rating="rating"
                              background-color="orange lighten-3"
                              color="orange"
                              large
                            >
                          </v-rating>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" align-self="center">
                          <h4>Did you enjoy eating it?</h4>
                        </v-col>
                        <v-col cols="12" sm="6" align-self="center">
                          <div :class="this.$vuetify.breakpoint.xs ? 'text-center': ''">
                            <v-rating
                              v-model="ratings['enjoy_eating']"
                              :rating="rating"
                              background-color="orange lighten-3"
                              color="orange"
                              large
                            >
                          </v-rating>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" align-self="center">
                          <h4>Would you recommend it?</h4>
                        </v-col>
                        <v-col cols="12" sm="6" align-self="center">
                          <div :class="this.$vuetify.breakpoint.xs ? 'text-center': ''">
                            <v-rating
                              v-model="ratings['recommend']"
                              :rating="rating"
                              background-color="orange lighten-3"
                              color="orange"
                              large
                            >
                          </v-rating>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" align-self="center">
                          <h4>Did it arrive on time?</h4>
                        </v-col>
                        <v-col cols="12" sm="6" align-self="center">
                          <div :class="this.$vuetify.breakpoint.xs ? 'text-center': ''">
                            <v-rating
                              v-model="ratings['arrive_on_time']"
                              :rating="rating"
                              background-color="orange lighten-3"
                              color="orange"
                              large
                            >
                          </v-rating>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" align-self="center">
                          <h4>Overall how was your takein experience?</h4>
                        </v-col>
                        <v-col cols="12" sm="6" align-self="center">
                          <div :class="this.$vuetify.breakpoint.xs ? 'text-center': ''">
                            <v-rating
                              v-model="ratings['quantity']"
                              :rating="rating"
                              background-color="orange lighten-3"
                              color="orange"
                              large
                            >
                          </v-rating>
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  
</template>
<script>
export default {
  props: {
    order: {
      Type: Object
    },
    data: {
      Type: Object
    }
  },
  data() {
    return {
      rating: null,
      ratings: {}
    }
  },

  mounted() {
    this.ratings = this.data || {}
  },

  watch: {
    data: function() {
      // watching data and ratings has infinite loop
      if (
        this.data &&
        (!this.ratings || Object.entries(this.ratings).length == 0)
      ) {
        this.ratings = this.data
      }
    },
    ratings: {
      handler: function() {
        if (this.ratings) {
          this.$emit('onRatingUpdate', this.ratings)
        }
      },
      deep: true
    }
  }
}
</script>
