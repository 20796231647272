<template>
  <v-container v-if="!loading">
    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="8" class="pa-0 ma-0">
        <producer-review
          :chef="chef"
          :data="producer_ratings"
          @onRatingUpdate="onProducerRatingUpdate"
        />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="8" class="pa-0 ma-0">
        <products-review
          v-if="order"
          :data="products_ratings"
          @onRatingUpdate="onProductsRatingUpdate"
          :products="order.products"
        />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="8" class="pa-0 ma-0">
        <delivery-review
          :order="order"
          :data="delivery_ratings"
          @onRatingUpdate="onDeliveryRatingUpdate"
        />
      </v-col>
    </v-row>
    <v-row class="pa-0 mx-3">
      <v-col align-self="center" class="pa-0 mb-4">
        <v-btn dark color="info darken-3" @click.stop="submitReview">Submit</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="showSubmitDialog" persistent max-width="390">
      <v-card>
        <v-card-title>
          <v-icon color="green" class="mr-1">{{ 'check_circle' }}</v-icon>
          Thank you for your feedback 
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click.stop="$router.push('/order/list'), showSubmitDialog = false"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ProducerReview from 'fod-core/src/components/review/ProducerReview.vue'
import ProductsReview from 'fod-core/src/components/review/ProductsReview.vue'

import DeliveryReview from './DeliveryReview'

export default {
  components: {
    ProducerReview,
    ProductsReview,
    DeliveryReview
  },
  data() {
    return {
      publicComment: null,
      privateComment: null,
      feedback: null,
      rating: null,
      delivery_ratings: null,
      products_ratings: null,
      producer_ratings: null,
      showSubmitDialog: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      //  chef: 'chef',
      order: 'order',
      review: 'review',
      loading: 'loading'
    }),
    chef() {
      if (this.order) {
        let chef = this.order.seller
        chef.id = this.order.sellerUserId
        return chef
      }
      return null
    }
  },
  mounted() {
    //let chefId = this.$route.params.id
    let orderId = this.$route.params.order_id
    
    //this.$store.dispatch('loadChefById', chefId)
    this.$store.dispatch('loadOrder', { orderId })
    this.$store
      .dispatch('loadOrderReview', {
        orderId: orderId
      })
      .then(data => {
        
        if (data) {
          this.rating = data.rating
          this.delivery_ratings = data.delivery_ratings
          this.producer_ratings = data.producer_ratings
          this.products_ratings = data.products_ratings
          this.publicComment = data.publicComment
          this.privateComment = data.privateComment
          this.feedback = data.feedback
        }
      })
    console.log('Trying to order foodId', orderId)
  },
  methods: {
    onDeliveryRatingUpdate(payload) {
      console.log('onDeliveryRatingUpdate ', payload)
      this.delivery_ratings = payload
    },
    onProducerRatingUpdate(payload) {
      console.log('onProducerRatingUpdate ', payload)
      this.producer_ratings = payload
    },
    onProductsRatingUpdate(payload) {
      console.log('onProductsRatingUpdate ', payload)
      this.products_ratings = payload
    },
    submitReview() {
      // gets product ids
      let product_ids = Object.keys(this.products_ratings)
  
      let review = {
        producer_id: this.chef.id,
        orderId: this.$route.params.order_id,
        delivery_ratings: this.delivery_ratings,
        producer_ratings: this.producer_ratings,
        products_ratings: this.products_ratings,
        product_ids: product_ids
      }
      
      this.$store.dispatch('saveOrderReview', review)
      console.log('Submitting review', review)
      this.showSubmitDialog = true
    }
  }
}
</script>
